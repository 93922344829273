import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer";

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "ImportChannel"
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {
    console.log('_connected')
  }

  _disconnected() {
    console.log('_disconnected')
  }

  _received(data) {
    console.log('_received', data)
  }
}
