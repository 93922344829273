import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'sourceClass', 'sourceValues' ]

  connect() {
    this.fetch_values();
  }

  async fetch_values()  {
    const sourceKlassValue = this.sourceClassTarget.value;
    const currentValues = $(this.sourceValuesTarget).val();

    if (sourceKlassValue == '') {
      return;
    } else {
      this.sourceValuesTarget.options.length = 0;
    }

    const response  = await fetch(`/api/v1/sources/${sourceKlassValue}/values`)
    const values = await response.json();

    const newOptions = values.map((item) =>  {
      return new Option(item.name, item.name, false, currentValues.includes(item.name))
    });

    $(this.sourceValuesTarget).append(newOptions).trigger('change');
  }

}
