import { Turbo } from "@hotwired/turbo-rails"
import "../../controllers"

import 'adminos/adminos';
import "chartkick/chart.js"

import { InitTree } from './modules/tree';
import { InitCocoonFields } from './modules/cocoon_fields';

Turbo.session.drive = false

InitTree();
InitCocoonFields();
